<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    :color="color"
    :top="position.top"
    :bottom="position.bottom"
    :left="position.left"
    :right="position.right"
    :centered="position.centered"
    outlined
  >
    <v-icon
      left
      :color="color"
    >
      {{ getIcon() }}
    </v-icon>
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn
        class="mx-2"
        fab
        x-small
        text
        v-bind="attrs"
        color="danger"
        @click="closeSnackbar()"
      >
        <v-icon
          dark
          small
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import {
  mdiAlertCircleOutline, mdiCheckCircleOutline,
  mdiClose, mdiCloseCircleOutline,
  mdiInformationOutline,
} from '@mdi/js'
import Vue from 'vue'

export default Vue.extend({
  data: () => ({
    snackbar: false,
    icons: {
      mdiClose,
      mdiCloseCircleOutline,
      mdiAlertCircleOutline,
      mdiInformationOutline,
      mdiCheckCircleOutline,
    },
  }),
  computed: {
    snackbarData() {
      return this.$store.getters.GetSnackbar
    },
    position() {
      return {
        top: this.snackbarData?.top || false,
        bottom: this.snackbarData?.bottom || false,
        right: this.snackbarData?.right || false,
        left: this.snackbarData?.left || false,
        centered: this.snackbarData?.centered || false,
      }
    },
    color() {
      return this.snackbarData?.color || 'primary'
    },
    timeout() {
      return this.snackbarData?.timeout || 6000
    },
    snackbarActive() {
      return this.snackbarData?.active ?? false
    },
    text() {
      return this.snackbarData?.text ?? ''
    },
  },
  watch: {
    snackbarActive(value) {
      this.snackbar = value
    },
    snackbar(value) {
      if (!value) this.closeSnackbar()
    },
  },
  methods: {
    getIcon() {
      let icon = this.icons.mdiInformationOutline
      if (this.color.includes('error')) {
        icon = this.icons.mdiCloseCircleOutline
      } else if (this.color.includes('warning')) {
        icon = this.icons.mdiAlertCircleOutline
      } else if (this.color.includes('success')) {
        icon = this.icons.mdiCheckCircleOutline
      }

      return icon
    },
    closeSnackbar() {
      this.$store.commit('setSnackbar', { active: false })
    },
  },
})
</script>
