import {
  mdiAccountGroup,
  mdiAccountGroupOutline,
  mdiAppsBox,
  mdiBriefcaseAccountOutline,
  mdiConnection,
  mdiEyeOutline, mdiFileDocumentEditOutline,
  mdiFileExportOutline, mdiFileOutline,
  mdiFileRefresh,
  mdiHomeAccount,
  mdiHomeGroup,
  mdiHomeOutline,
  mdiLinkBox,
  mdiShieldKeyOutline,
  mdiTableKey, mdiToolboxOutline,
  mdiTransitConnectionHorizontal,
} from '@mdi/js'

export default [
  {
    title: 'Home',
    icon: mdiHomeOutline,
    to: 'home',
  },
  {
    title: 'Bitacoras',
    icon: mdiFileRefresh,
    children: [
      {
        title: 'Tarifas',
        icon: mdiFileOutline,
        to: 'bitacora-tarifas',
      },
      {
        title: 'Tarifas Integrador',
        icon: mdiFileOutline,
        to: 'bitacora-tarifas-integrador',
      },
      {
        title: 'Tass',
        icon: mdiFileOutline,
        to: 'bitacora-tass',
      },
      {
        title: 'API-Ventas',
        icon: mdiFileOutline,
        to: 'ventas',
      },
      {
        title: 'Api Forum',
        icon: mdiFileOutline,
        to: 'bitacora-api-forum',
      },
      {
        title: 'Documentos',
        icon: mdiFileOutline,
        to: 'bitacora-documentos',
      },
    ],
  },
  {
    title: 'Notificaciones',
    icon: mdiEyeOutline,
    children: [
      { title: 'Plantillas', icon: mdiFileExportOutline, to: 'noti-plantillas' },
      { title: 'Configuraciones', icon: mdiFileDocumentEditOutline, to: 'noti-configuraciones' },
      { title: 'Bitacoras', icon: mdiFileRefresh, to: 'noti-bitacoras' },
      { title: 'Clientes', icon: mdiAccountGroupOutline, to: 'noti-client' },
      { title: 'Parametros', icon: mdiAppsBox, to: 'noti-param' },
    ],
  },
  {
    title: 'AUTH',
    icon: mdiShieldKeyOutline,
    children: [
      { title: 'Permisos', icon: mdiTableKey, to: 'auth-permissions' },
      { title: 'Servicios', icon: mdiToolboxOutline, to: 'auth-services' },
      { title: 'App Grupos', icon: mdiHomeGroup, to: 'auth-group' },
      { title: 'Aplicaciones', icon: mdiHomeAccount, to: 'auth-app' },
      { title: 'Endpoints', icon: mdiLinkBox, to: 'auth-endpoints' },
      { title: 'Roles', icon: mdiBriefcaseAccountOutline, to: 'auth-roles' },
      { title: 'Usuarios', icon: mdiAccountGroup, to: 'auth-users' },
    ],
  }, {
    title: 'Integraciones',
    icon: mdiConnection,
    children: [
      {
        title: 'FORUM',
        icon: mdiTransitConnectionHorizontal,
        children: [
          { title: 'Operaciones', to: 'forum-operations' },
        ],
      },
    ],
  },
]
