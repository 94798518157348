import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import auth from './app/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackbar: {
      active: false,
      text: '',
      timeout: 6000,
      color: '',
      top: false,
      bottom: false,
      right: false,
      left: false,
      centered: false,
    },
  },
  mutations: {
    setSnackbar(state, payload) {
      state.snackbar.text = payload?.text ?? ''
      state.snackbar.active = payload?.active ?? false
      state.snackbar.timeout = payload?.timeout ?? 6000
      state.snackbar.color = payload?.color ?? ''
      state.snackbar.top = payload?.top ?? false
      state.snackbar.left = payload?.left ?? false
      state.snackbar.right = payload?.right ?? false
      state.snackbar.bottom = payload?.bottom ?? false
      state.snackbar.centered = payload?.centered ?? false
    },
  },
  getters: {
    GetSnackbar(state) {
      return state.snackbar
    },
  },
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    auth,
  },
})
