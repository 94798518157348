export default {
  namespaced: true,
  state: {
    isLogged: !!sessionStorage.getItem('accessToken'),
    accessToken: sessionStorage.getItem('accessToken') ?? '',
  },
  getters: {
    IS_LOGGED(state) {
      return state.isLogged
    },
  },
  mutations: {
    LOGGED(state, value) {
      sessionStorage.setItem('accessToken', value)
      state.isLogged = value !== undefined
      state.accessToken = value
    },
    LOGOUT(state) {
      sessionStorage.removeItem('accessToken')
      state.isLogged = false
    },
  },
  actions: {},
}
