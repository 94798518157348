const routes = [
  {
    path: '/auth-permisos',
    name: 'auth-permissions',
    component: () => import('@/views/Auth/Permisos.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/auth-servicios',
    name: 'auth-services',
    component: () => import('@/views/Auth/Servicios.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/auth-grupos',
    name: 'auth-group',
    component: () => import('@/views/Auth/Grupos.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/auth-app',
    name: 'auth-app',
    component: () => import('@/views/Auth/Apps.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/auth-endpoints',
    name: 'auth-endpoints',
    component: () => import('@/views/Auth/Endpoints.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/auth-roles',
    name: 'auth-roles',
    component: () => import('@/views/Auth/Roles.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/auth-usuarios',
    name: 'auth-users',
    component: () => import('@/views/Auth/Users.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default routes
