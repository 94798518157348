const routes = [
  {
    path: '/forum-operaciones',
    name: 'forum-operations',
    component: () => import('@/views/Integrations/forum/Operations.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default routes
