import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

import AuthRoutes from './authRoutes'
import IntRoutes from './integrationRoutes'
import NotRoutes from './notificationRoutes'

Vue.use(VueRouter)

let routes = [
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/home/services-status',
    name: 'services-status',
    component: () => import('@/views/cortes/Estatus.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/bitacora-tarifas',
    name: 'bitacora-tarifas',
    component: () => import('@/views/bitacoras/Tarifas.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/bitacora-tarifas-integrador',
    name: 'bitacora-tarifas-integrador',
    component: () => import('@/views/bitacoras/Tarifas-Integrador.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },
  {
    path: '/bitacora-tass',
    name: 'bitacora-tass',
    component: () => import('@/views/bitacoras/Tass.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/bitacora-documentos',
    name: 'bitacora-documentos',
    component: () => import('@/views/bitacoras/Documentos.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/ventas',
    name: 'ventas',
    component: () => import('@/views/bitacoras/Ventas.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },
  {
    path: '/bitacora-api-forum',
    name: 'bitacora-api-forum',
    component: () => import('@/views/bitacoras/Forum.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/bitacora-detalle/:id',
    name: 'bitacora-detalle',
    component: () => import('@/views/bitacoras/Detalle.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

routes = routes.concat(NotRoutes, AuthRoutes, IntRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const publicPages = ['/login']
  const authpage = !publicPages.includes(routeTo.path)
  const loggeduser = store.getters['auth/IS_LOGGED']
  if (authpage && !loggeduser) {
    return next('/login')
  }

  return next()
})

export default router
