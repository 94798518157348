const routes = [
  {
    path: '/notificaciones-plantillas',
    name: 'noti-plantillas',
    component: () => import('@/views/Notificaciones/Plantillas.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/notificaciones-configuraciones',
    name: 'noti-configuraciones',
    component: () => import('@/views/Notificaciones/Configuraciones.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/notificaciones-bitacoras',
    name: 'noti-bitacoras',
    component: () => import('@/views/Notificaciones/Bitacoras.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/notificaciones-clientes',
    name: 'noti-client',
    component: () => import('@/views/Notificaciones/Clientes.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/notificaciones-parametros',
    name: 'noti-param',
    component: () => import('@/views/Notificaciones/Parametros.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default routes
